<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseSlugs.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Video.Items.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader class="d-flex align-items-center justify-content-between">
          <h5 class="mb-0">{{ $t('Navigation.Video/MediaStorage') }}</h5>
          <CButton color="success" shape="pill" @click="UploadModal = true">
            <i class="fas fa-cloud-upload-alt mr-2" /> {{ $t('Global.Upload') }}
          </CButton>
        </CCardHeader>
        <template v-if="!$store.state.project.StreamSourceBucket">
          <CCardBody>
            <div class="jumbotron bg-white text-center py-5">
              <h2 class="display-6 font-weight-bold mb-3">尚未啟用此服務</h2>
              <p class="lead">若您不需仰賴第三方多媒體平台(如: Vimeo)，可使用多媒體物件空間使用自管的串流服務，請洽業務專員瞭解更多資訊。</p>
            </div>
          </CCardBody>
        </template>
        <template v-else>
          <CCardHeader>
            <CRow>
              <CCol col="12">
                <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                  <CIcon name="cil-filter"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
                  {{ $t('Post/Detail.CreateTime') }}: {{
                    SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
                  }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Slug')">
                  {{ $t('Post/List.Slug') }}: {{ SearchFilter.Slug.join(',') || '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
                  {{ $t('Post/List.Name') }}: {{ SearchFilter.Name || '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Status')">
                  {{ $t('Post/List.Status') }}: {{ SearchFilter.Status.map(item => item.label).join(',') || '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Tags')">
                  {{ $t('Post/List.Tags') }}: {{ SearchFilter.Tags.join(',') || '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
                  {{ $t('Product/Detail.Sort') }}: {{ $t('Product/List.SortType.' + this.Order) || '--' }}
                  <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                </CButton>
                <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
                  <CIcon size="sm" name="cil-x"/>
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">
            <CDataTable
              id="PostList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
            >
              <template #Checkbox-header>
                <label class="mb-0">
                  <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
                </label>
              </template>
              <template #Checkbox="{item}">
                <td class="text-center">
                  <label class="mb-0">
                    <input type="checkbox" :id="'Checkbox-Video-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                  </label>
                </td>
              </template>
              <template #Cover="{item}">
                <td>
                  <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                </td>
              </template>
              <template #Name="{item}">
                <td>
                  <a href="javascript:void(0)" @click="GetVideoLink(item.Variable.name)">
                    {{ item.Name }}
                  </a>
                </td>
              </template>
              <template #Status="{item}">
                <td>
                  <CBadge v-if="!StatusLoading" :color="(item.Status === 'available' ? 'success' : 'danger')">
                    {{ $t('Video/Storage.StatusType.' + item.Status) }}
                  </CBadge>
                  <i v-else class="fas fa-spinner fa-pulse" />
                </td>
              </template>
              <template #MIMEType="{item}">
                <td>
                  {{ item.Variable.contentType }}
                </td>
              </template>
              <template #CreateTime="{item}">
                <td>
                  {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
                </td>
              </template>
              <template #Action="{item}">
                <td>
                  <CButton
                    variant="ghost"
                    :color="item.EnableStream ? 'success' : 'info'"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{content: item.EnableStream ? '串流供應中' : '處理中', placement: 'top'}"
                    @click="GetVideoStream(item)"
                  >
                    <i :class="item.EnableStream ? `fas fa-stream` : `fas fa-spinner fa-pulse`" />
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <CCardFooter>
            <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
            />
          </CCardFooter>
        </template>
      </CCard>
    </CForm>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :size="(ShowFilter !== 'All' ? 'md' : 'lg')" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit"
            @update:show="ChangePage()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Slug'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/List.Slug') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Slug"
            v-model="SearchFilter.Slug"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.Slug"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" v-model="SearchFilter.Name" :label="$t('Post/List.Name')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Tags'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/List.Slug') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Tags"
            v-model="SearchFilter.Tags"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="SearchFilter.Tags"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Status'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/List.Status') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Status"
            v-model="SearchFilter.Status"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="StatusOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" horizontal :label="$t('Order.Filter.Sort')" :options="SortOption" v-model="Order" :value.sync="Order" :placeholder="$t('Global.PleaseSelect')"/>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="UploadModal" class="ActionModel" size="lg" :show.sync="UploadModal" :centered="true" :closeOnBackdrop="!Submit">
      <Dropzone
        id="DropUpload"
        ref="DropUpload"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        class="mb-3"
        @vdropzone-sending-multiple="MultipleUpload"
      >
        <CIcon name="cil-image-plus" class="c-icon-custom-size" size="lg" />
        <span class="d-block">{{ $t('Global.DragAndUpload') }}</span>
      </Dropzone>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Media.DoVideoUpload') }}</h5>
          <CButtonClose @click="UploadModal = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="$refs.DropUpload.removeAllFiles(true)" color="light">{{ $t('Global.Cancel') + $t('Global.Upload') }}</CButton>
          <CButton id="UploadSubmit" @click="UploadModal = false" color="success">
            {{ $t('Global.Finish') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "多媒體物件空間"
  }
}
</route>

<script>
import Firebase from "firebase/app";
import dayjs from "dayjs";

export default {
  name: 'VideoStorage',
  layout: 'manage',
  components: {
    Dropzone: () => import('@/plugins/dropzone'),
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data () {
    return {
      List: [],
      AlertModel: false,
      UploadModal: false,
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 12,
        TotalItems: 0,
        TotalPages: 0
      },
      ShowFilter: 'All',
      FilterModel: false,
      SearchFilter: {
        StartTime: '',
        EndTime: '',
        Slug: [],
        Tags: [],
        Name: '',
        Status: []
      },
      Order: 'create_nto',
      Loading: false,
      StatusLoading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Video/Storage.NoResults'),
        noItems: this.$t('Video/Storage.NoItems')
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        uploadMultiple: true,
        parallelUploads: 1,
        maxFiles: 3,
        maxFilesize: 1024 * 100, // MB
        addRemoveLinks: true
      },
    }
  },
  computed: {
    // dropzoneOptions () {
    //   return {
    //     url: '/import/video/storage/upload?token=' + localStorage.getItem('XX-CSRF-Token'),
    //     chunking: true,
    //     forceChunking: true,
    //     chunkSize: 1024 * 1024 * 10, // 1MB,
    //     parallelChunkUploads: false, // 並行上傳所有分段
    //     retryChunks: true, // 如果上傳失敗，則重試
    //     retryChunksLimit: 2, // 重試次數
    //     init: function() {
    //       this.on('sending', function(file, xhr, formData) {
    //         // 在送出前，將檔名加入formData
    //         formData.append('filename', file.name);
    //       });
    //     },
    //     // timeout: 300000,
    //     // maxFiles: 1,
    //     maxFilesize: 1024 * 100, // GB
    //     // addRemoveLinks: true,
    //     headers: {
    //       'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
    //     }
    //   }
    // },
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: this.$t('Video/Storage.Cover') },
        { key: 'Name', label: this.$t('Video/Storage.Name') },
        { key: 'Status', label: this.$t('Video/Storage.Status') },
        { key: 'CreateTime', label: this.$t('Post/Detail.CreateTime') },
        { key: 'Action', label: '' }
      ]
    },
    StatusOptions() {
      return Object.keys(this.$t('Product/List.StatusOptions')).map(value => {
        return {
          label: this.$t('Product/List.StatusOptions.' + value),
          value
        }
      })
    },
    SortOption() {
      return [
        {value: 'otn', label: this.$t('Product/List.SortType.otn')},
        {value: 'nto', label: this.$t('Product/List.SortType.nto')},
        {value: 'lth', label: this.$t('Product/List.SortType.lth')},
        {value: 'htl', label: this.$t('Product/List.SortType.htl')},
        {value: 'sort_asc', label: this.$t('Product/List.SortType.sort_asc')},
        {value: 'sort_desc', label: this.$t('Product/List.SortType.sort_desc')}
      ]
    },
    PerPageOption() {
      return Object.keys(this.$t('Order/List.PerPageOption')).map(option => {
        return {
          value: option,
          label: this.$t('Order/List.PerPageOption')[option]
        }
      })
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).then(() => {
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'Status':
              this.SearchFilter[param] = this[`${param}Options`].filter(data => this.$route.query[param].split(',').includes(data.value))
              break
            case 'StartTime':
            case 'EndTime':
              this.SearchFilter[param] = parseInt(this.$route.query[param]) * 1000
              break
            case 'Name':
              this.SearchFilter[param] = this.$route.query[param]
              break
          }
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        Page: this.$route.query.Page || this.Pagination.Page,
        PerPage: this.$route.query.PerPage || this.Pagination.PerPage,
        Order: this.$route.query.Order || this.Order
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/video/storage/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.Data
        this.Pagination = data.Pagination
        this.StatusLoading = true
        setTimeout(() => {
          const Executes = data.Data.map((item) => {
            return this.$axios.get(`https://${this.$store.state.project.StreamBucket}/${item.Slug}/manifest.m3u8`)
          })
          return Promise.allSettled(Executes).then((result) => {
            result.forEach((data, index) => {
              if (data.status !== 'fulfilled' && (dayjs().unix() - dayjs(this.List[index].CreateTime).unix()) > 3600) {
                this.$set(this.List[index], 'Status', 'unavailable')
              }
              if (data.status === 'fulfilled') {
                this.$set(this.List[index], 'Status', 'available')
              }
              this.$set(this.List[index], 'EnableStream', data.status === 'fulfilled')
            })
            this.StatusLoading = false
          })
        }, 2000)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage,
        Order: this.Order
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        switch (typeof this.SearchFilter[param]) {
          case 'string':
          case 'number':
            if (this.SearchFilter[param]) {
              query[param] = this.SearchFilter[param]
            }
            break
          case 'object':
            if (Array.isArray(this.SearchFilter[param]) && this.SearchFilter[param].length > 0) {
              query[param] = this.SearchFilter[param].join(',')
            } else {
              switch (param) {
                case 'StartTime':
                case 'EndTime':
                  query[param] = this.$dayjs(this.SearchFilter[param]).unix()
                  break
              }
            }
            break
        }
      })
      this.$router.replace({
        path: '/video/storage',
        query
      }).then(() => {
        this.FilterModel = false
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/delete',
          Data: {
            Taxonomy: 'VideoStorageObject',
            Slug: this.ChooseSlugs,
            Manage: true
          }
        }
      }).then(() => {
        this.Init()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: '',
        EndTime: '',
        Slug: [],
        Tags: [],
        Name: '',
        Status: []
      }
      this.ChooseIndex = []
      this.$router.replace({
        path: '/video/storage'
      }).then(() => {
        this.Init()
      })
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    CompleteUpload() {
      this.Init()
      this.$refs.DropUpload.removeAllFiles(true)
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Success'),
        text: this.$t('Message.Media/UploadSuccess'),
        type: 'success'
      })
    },
    MultipleUpload(files) {
      const Permission = JSON.parse(localStorage.getItem('Permission'))
      let SourceApp
      if (Firebase.apps.find((app) => app.name === 'sourceApp')) {
        SourceApp = Firebase.apps.find((app) => app.name === 'sourceApp')
      } else {
        SourceApp = Firebase.initializeApp({
          apiKey: Permission.AppSetting.apiKey,
          storageBucket: this.$store.state.project.StreamSourceBucket || this.$store.state.project.StreamBucket || Permission.AppSetting.storageBucket,
          projectId: Permission.AppSetting.projectId,
          appId: Permission.AppSetting.appId
        }, 'sourceApp')
      }
      const storageInstance = SourceApp.storage()
      const FilesUpload  = files.map(file => {
        return storageInstance.ref('/' + file.name).put(file)
      })
      return Promise.all(FilesUpload).then((result) => {
        this.Init()
        result.forEach((snapShot) => {
          this.$store.dispatch('InnerRequest', {
            url: '/video/storage/upload/complete',
            method: 'post',
            data: {
              filename: snapShot.metadata.name
            }
          })
        })
        this.$refs.DropUpload.removeAllFiles(true)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Media/UploadSuccess'),
          type: 'success'
        })
        return true
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Media/UploadFail') + err,
          type: 'error'
        })
      })
    },
    GetVideoLink(name) {
      this.$store.dispatch('InnerRequest', {
        url: '/video/storage/url',
        method: 'post',
        data: {
          name
        }
      }).then(({ data }) => {
        window.open(data.Data, '_blank')
      })
    },
    GetVideoStream(item) {
      return window.open(`https://${this.$store.state.project.StreamBucket}/${item.Slug}/manifest.m3u8`, '_blank')
    }
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#PostList thead th:first-child {
  text-align: center;
}
#PostList tbody td:first-child {
  vertical-align: middle;
}
</style>
